import timg1 from '../images/team/Abdus.jpeg'
import timg2 from '../images/team/julhas.png'
import timg3 from '../images/team/team_member_image_3.webp'
// import timg4 from '../images/team/team_member_image_4.webp'
// import timg5 from '../images/team/team_member_image_5.webp'


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      name: 'Abdus Shahid',
      slug: 'Abdus Shahid',
      title: 'Software Engineer',
      email:'abdusshahid11399@gmail.com'
   },
   {
      Id: '2',
      tImg: timg2,
      name: 'Julhas Sk',
      slug: 'Julhas Sk',
      title: 'IT Consultant',
      email:'jk1999@gmail.com'

   },
   {
      Id: '2',
      tImg: timg3,
      name: 'Samim Hossain',
      slug: 'Samim Hossain',
      title: 'Marketing Manager',
      email:'demo@gmail.com'

   },
   // {
   //    Id: '3',
   //    tImg: timg3,
   //    name: 'August Everest',
   //    slug: 'August-Everest',
   //    title: 'systems engineer',
   // },
   // {
   //    Id: '4',
   //    tImg: timg4,
   //    name: 'Maverick Phoenix',
   //    slug: 'Maverick-Phoenix',
   //    title: 'data analyst',
   // },
   // {
   //    Id: '5',
   //    tImg: timg5,
   //    name: 'Daxton Atlas',
   //    slug: 'Daxton-Atlas',
   //    title: 'Project Manager',
   // },
   // {
   //    Id: '6',
   //    tImg: timg4,
   //    name: 'Maverick Phoenix',
   //    slug: 'Maverick-Phoenix',
   //    title: 'data analyst',
   // },
   
]

export default Teams;